<template>
  <div class="secure-report container template-1">
    <header class="page-header container mx-lg">
      <h1>{{ translations.components['report-parameters'].tcWorldSummaries }}</h1>
    </header>
    <section class="container bg-block">
      <h3>{{ reportName }}</h3>
      <b-form-group>
        <div class="form_label"><strong>{{ translations.components['report-parameters'].tcReports }}</strong></div>
        <b-form-select v-model="worldReport.key" :plain="true" @change="worldReportChange($event)">
          <option selected="selected" Value="">{{ translations.components['report-parameters'].tcSelectAReport }}</option>
          <option Value="CAWSFRPT">{{ translations.components['report-parameters'].tcScriptureReceipts }}</option>
          <option Value="CAWSMRPT">{{ translations.components['report-parameters'].tcMembershipPerformance }}</option>
          <option Value="CAWSPRPT">{{ translations.components['report-parameters'].tcScripturePlacements }}</option>
        </b-form-select>
      </b-form-group>
      <b-form-group class="form-element">
        <div class="form_label"><strong>{{ translations.components['report-parameters'].tcYear }}</strong></div>
        <b-form-select
          class="form-control g-select w-full"
          :options="worldReportYears"
          :plain="true"
          v-model="worldReportYear"
          @change="worldReportYearChange($event)"
        ></b-form-select>
      </b-form-group>
      <b-form-group class="form-element">
        <div v-for="(ReportDetail, index) in this.worldReportUrls" :key="`g${index}`">
          <b-link target="_blank" :href="ReportDetail.value"
            ><strong>{{ ReportDetail.text }}</strong></b-link
          >
        </div>
      </b-form-group>
      <b-form-group>
        <b-button class="btn btn-tertiary btn-w-med ml-4" size="sm" id="btnCancelForm" @click="handleCancelClick"
          >{{ translations.components['report-parameters'].tcCancel }}</b-button
        >
      </b-form-group>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'world-summaries',
  mixins: [translationMixin],
  data() {
    return {
      translations: {
        components: {
          'report-parameters': [],
        },
      },
      title: 'World Summaries',
      values: {},
      reportName: '',
      reportParamParentKey: '',
      worldReportUrls: null,
      worldReport: {
        key: null,
        value: null,
      },
      worldReportYear: null,
      worldReportYears: [],
      worldReportUrls: null,
      worldReportYearsData: {
        worldReport: null,
      },
      worldReportUrlsData: {
        worldReport: null,
        worldReportYear: null,
      },
    }
  },
  methods: {
    ...mapActions({
      getWorldUrls: 'secureReport/getWorldUrls',
      getWorldYears: 'secureReport/getWorldYears',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async page_load() {
      this.setLoadingStatus(true)
      this.worldReport.key = ''
      this.worldReportYear = ''
      this.setLoadingStatus(false)
    },
    async worldReportChange(evt) {
      this.setLoadingStatus(true)
      this.worldReportYearsData.worldReport = this.worldReport.key
      await this.getWorldYears(this.worldReportYearsData)
      this.worldReportYears = this.worldYearDetails.map((item) => {
        return { text: item, value: item }
      })
      this.worldReportYears.unshift({ text: this.translations.components['report-parameters'].tcSelectAYear, value: '' })
      this.worldReportYear = ''
      this.worldReportUrls = null
      this.setLoadingStatus(false)
    },
    async worldReportYearChange(evt) {
      this.setLoadingStatus(true)
      this.worldReportUrlsData.worldReport = this.worldReport.key
      this.worldReportUrlsData.worldReportYear = this.worldReportYear

      await this.getWorldUrls(this.worldReportUrlsData)
      this.worldReportUrls = this.worldUrlDetails.map((item) => {
        var objDate = new Date()
        objDate.setDate(1)
        objDate.setMonth(item.Month - 1)
        var month = objDate.toLocaleString(this.prefCulture, { month: 'long' })
        return { text: month + ' ' + item.Year, value: item.URL }
      })
      this.setLoadingStatus(false)
    },
    handleCancelClick() {
      this.$router.push({ path: `/reports/report-center` })
    },
    setWorldReportYear() {
      this.worldReportYears.unshift({
        text: this.translations.components['report-parameters'].tcSelectAYear,
        value: '',
      })
    },
  },
  async created() {
    await this.getPseudoComponentTranslations('report-parameters').then((results) => {
      const pseudoComponentTranslations = results
      this.$set(this.translations, 'components', pseudoComponentTranslations)
      this.setWorldReportYear()
    })
    await this.page_load()
  },
  computed: {
    ...mapGetters({
      demograph: 'user/demograph',
      prefCulture: 'user/userPreferredCulture',
      reportParentKey: 'reportParameter/reportParentKey',
      selectedReportKey: 'reportParameter/selectedReportKey',
      userCamp: 'user/userCamp',
      userCampKey: 'user/userCampKey',
      userId: 'user/userId',
      worldUrlDetails: 'secureReport/worldReportUrls',
      worldYearDetails: 'secureReport/worldReportYears',
    }),
  },
  components: {},
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';

select {
  height: auto !important;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
  max-width: 300px;
}

input[type='date'] {
  border: 1px solid #636363;
  padding: 13px 20px 13px 60px;
  height: auto;
  font-weight: bold;
  font-size: 16px;
}

input[type='checkbox'] {
  height: 30px;
  width: 30px;
}

.rpt-checkbox {
  font-weight: bolder;
  margin: 30px 15px 10px 0px;
}

.rpt-label {
  font-weight: bolder;
  margin-top: 30px;
}

.rpt-checkbox + div {
  display: inline-block;
}

div .report-download-btn {
  margin: 30pt 0px 10px 0px;
}
</style>
